<template>
  <svg width="26" height="24" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g opacity="0.5">
<path d="M9.07544 10L13.3019 14L17.5283 10" stroke="#382156" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</g>
</svg>

</template>

<script>
export default {
name:"DropdownIcon"
}
</script>

<style>

</style>