<template>
  <div class="px-5 pt-5 pb-10 flex flex-col justify-between h-full overflow-scroll">
    <div>
      <router-link :to="{ name: 'StepOne' }">
       <BackIcon class="mb-10" />
      </router-link>
      
      <h3 class=" text-center text-lg mb-10">Before we get started let's get to
        know
        each other a little better</h3>
      <div class=" space-y-5 ">
        <button
          class="w-full flex justify-between rounded-full bg-white px-4 py-4 outline-none shadow-xl placeholder-darkBlue font-medium"
          placeholder="Age" required max-length="255">
          <span class="text-darkViolet opacity-50">Age</span>
          <span>
            <DropdownIcon />
          </span>
        </button>

        <button
          class="w-full flex justify-between rounded-full bg-white px-4 py-4 outline-none shadow-xl placeholder-darkBlue font-medium"
          placeholder="Age" required max-length="255">
          <span class="text-darkViolet opacity-50">Gender</span>
          <span>
            <DropdownIcon />
          </span>
        </button>
      </div>

    </div>

    <div>
      <div class="flex space-x-1 items-center justify-center mb-10">
        <div v-for="item in items" :key="item.id"
          class=" rounded-full h-2 w-2 bg-customPurple"></div>
      </div>
      <router-link class="w-10/12" :to="{ name: 'StepThree' }">
        <MainButton text="Next" class="h-14" />
      </router-link>
    </div>

  </div>

</template>

<script>
import BackIcon from "@/components/svg/BackIcon.vue";
import MainButton from "@/components/shared/MainButton.vue";
import DropdownIcon from "@/components/svg/DropdownIcon.vue";

export default {
  name: "StepTwo",

  components: {
    BackIcon,
    MainButton,
    DropdownIcon,
  },

  data() {
    return {
      items: ["Item1", "Item2", "Item3", "Item4", "Item5", "Item6"],
    };
  },
};
</script>
